import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

export const BlueTextField = withStyles({
  root: {
    border: 0,
    // '& label.Mui-focused': {
    //   color: '#319CE5',
    // },
    // '& .MuiInput-underline:after': {
    //   borderBottomColor: '#319CE5',
    // },
    '& .MuiOutlinedInput-root': {
      background: '#f3f3f3',
      borderRadius: '1.5rem',
        '& fieldset': {
        borderColor: 'transparent',
        borderRadius: '1.5rem',
      },
      '&:hover fieldset': {
        borderColor: '#0684FC',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#319CE5',
      },
    },
  },
})(TextField);
