import { observer } from 'mobx-react-lite';
import React from 'react';

export interface SchemaProps {
  schemaType: string;
  postalAddress?: {
    addressLocality: string;
    postalCode: string;
    streetAddress: string;
  };
  aggregateRating?: {
    ratingValue: string;
    reviewCount: string;
  };
  email?: string;
  name?: string;
  image?: string;
  telephone?: string;
}

export function buildSchemaObject(inputProps: SchemaProps) {
  const schemaObject = {
    '@context': 'https://schema.org',
    '@type': inputProps.schemaType
  };

  for (let key in inputProps) {
    switch (key) {
      case 'postalAddress':
        schemaObject['address'] = {
          '@type': 'PostalAddress',
          addressLocality: inputProps.postalAddress.addressLocality,
          postalCode: inputProps.postalAddress.postalCode,
          streetAddress: inputProps.postalAddress.streetAddress
        };
        break;
      case 'aggregateRating':
        schemaObject['aggregateRating'] = {
          '@type': 'AggregateRating',
          ratingValue: inputProps.aggregateRating.ratingValue,
          reviewCount: inputProps.aggregateRating.reviewCount
        };
        break;
      case 'email':
        schemaObject['email'] = inputProps.email;
        break;
      case 'name':
        schemaObject['name'] = inputProps.name;
        break;
      case 'image':
        schemaObject['image'] = inputProps.image;
        break;
      case 'telephone':
        schemaObject['telephone'] = inputProps.telephone;
        break;
      default:
        break;
    }
  }
  return schemaObject;
}

interface SEOSchemaProps {
  schema: SchemaProps;
}

function SEOSchema({ schema }: SEOSchemaProps) {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(buildSchemaObject(schema)) }}
    ></script>
  );
}

export default observer(SEOSchema);
