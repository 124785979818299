import Head from 'next/head';
import SEO from './SEO';
import Header from './Header';
import Footer from './Footer';
import ApplyNowModal from 'components/home/ApplyNowModal';
import { SchemaProps } from './SEOSchema';

interface Props {
  meta?: any;
  schema?: SchemaProps;
  title?: string;
  header?: boolean;
  showMenu?: boolean;
  children: React.ReactNode;
  applyNowOpen?: boolean;
  handleSetApplyNowOpen?: any;
}

function Layout({
  meta,
  schema,
  title,
  // description,
  header = true,
  showMenu = true,
  children,
  applyNowOpen,
  handleSetApplyNowOpen = null,
  ...props
}: Props) {
  // const { globalProps } = useStores()
  const isProduction = process.env.NODE_ENV === 'production';

  // useEffect(() => {
  //     const path = window.location.hash
  //     if (path && path.includes('#')) {
  //         const id = path.replace('#', '')
  //         const el = window.document.getElementById(id)
  //         const r = el.getBoundingClientRect()
  //         console.log(el, r)
  //         window.scrollTo({
  //             top: r.top,
  //             behavior: 'smooth'
  //         })
  //     }
  // }, [])
  return (
    <>
      <Head>
        <title>{(meta && meta.title) || title || 'StayQrious'}</title>
        {meta && <SEO meta={meta} schema={schema} />}
      </Head>
      {header ? (
        <Header
          applyNowOpen={applyNowOpen}
          handleSetApplyNowOpen={handleSetApplyNowOpen}
          showMenu={showMenu}
        />
      ) : null}
      {children}
      <Footer />

      {applyNowOpen && (
        <ApplyNowModal open={applyNowOpen} setOpen={handleSetApplyNowOpen} />
      )}
    </>
  );
}

export default Layout;
