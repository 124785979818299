import { createUseStyles } from 'react-jss';
import React from 'react';
import clsx from 'clsx';

const useStyles = createUseStyles({
  switchField: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: 4,
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  switchItem: {
    backgroundColor: '#E4E4E4',
    color: '#707070',
    fontSize: 14,
    lineHeight: 1,
    fontWeight: 500,
    textAlign: 'center',
    padding: [16, 16],
    margin: [5, 5, 0, 0],
    borderRadius: 5,
    transition: 'all 0.1s ease-in-out',
    cursor: 'pointer',
    border: '1px solid #E4E4E4',
    boxShadow: 'none',
    '&:hover': {
      background: '#f4f4f4',
      border: '1px solid #ccc'
    }
  },
  switchItemSelected: {
    background: '#FCEBB2',
    border: '1px solid #F3A335',
    '&:hover': {
      background: '#FCEBB2',
      border: '1px solid #F3A335'
    }
  },
  rounded: {
    borderRadius: '50px'
  },
  '@media screen and (max-width: 760px)': {
    switchField: {
      justifyContent: 'flex-start'
    }
  }
});

interface OptionType {
  label?: string;
  value: string;
}
interface ISwitchFieldProps {
  options: Array<OptionType>;
  value: string;
  onChange: (value: string) => void;
  rounded?: boolean;
}

function SwitchField({
  options,
  value,
  onChange,
  rounded = false
}: ISwitchFieldProps) {
  const classes = useStyles();

  return (
    <div className={classes.switchField}>
      {options.map((opt) => (
        <div
          key={opt.value}
          className={clsx(classes.switchItem, {
            [classes.switchItemSelected]: opt.value === value,
            [classes.rounded]: rounded
          })}
          onClick={() => {
            onChange(opt.value);
          }}
        >
          {opt.label || opt.value}
        </div>
      ))}
    </div>
  );
}

export default SwitchField;
