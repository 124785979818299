import { createUseStyles } from "react-jss";
import { BlueTextField } from "components/styledComponents/TextField";
import Loading from "components/styledComponents/Loading";
import { InputAdornment, Typography, FormHelperText, MenuItem } from '@material-ui/core';
import SwitchField from 'components/styledComponents/SwitchField';
import PhoneInput from 'react-phone-input-2';
import { useStores } from "hooks/useStores";
import config from "config";
import GraduateCapSVG from 'assets/redesign/icons/graduateCap.svg';
import Person from 'assets/redesign/icons/person.svg';
import { observer } from "mobx-react-lite";
import 'react-phone-input-2/lib/style.css'
import { useEffect } from "react";
import { withCookies } from "react-cookie";
import Button from "components/generics/Button";
import { useRouter } from "next/router";
import { useState } from "react";
import { notify } from "components/styledComponents/Notification";


const useStyles = createUseStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: 10,
  },
  field: {
    margin: '10px 0px !important',
    fontWeight: 700,
    width: '100%',
    '& .MuiInputBase-input': {
      fontWeight: 400,
    },
    '& .MuiOutlinedInput-input': {
      borderRadius: '20px !important',
    },
  },
  phoneNumberContainer: {
    width: '100%',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  formtext: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    fontSize: "16px",
    margin: [5, 0],
    '& p': {
      color: '#707070',
    },
  },
  submitButton: {
    width: '50%',
    minWidth: 100,
    maxWidth: 200,
    height: 40,
    fontWeight: 'bold',
    fontSize: 16,
    alignSelf: 'center',
  },
  signinButton: {
    backgroundColor: '#d3eaff',
    borderRadius: 10,
    display: 'inline-block',
    padding: [4, 8],
  },
  phoneInput: {
    padding: "28px 14px",
    width: '100% !important',
    background: '#f3f3f3 !important',
    border: '0px !important',
    borderRadius: '1.5rem !important',
  },
  phoneContainer: {
    margin: [10, 0],
    border: '0px solid #319CE5 !important',
    borderRadius: '5px',
    "& div.flag-dropdown": {
      borderRadius: "1.5rem 0 0 1.5rem",
    }
  },
  phoneError: {
    margin: [10, 0],
    border: '1px solid red !important',
    borderRadius: '1.5rem',
  },
  skip: {
    width: '50%',
    minWidth: 100,
    maxWidth: 200,
    height: 25,
    fontWeight: 400,
    fontSize: 16,
    border: 'none',
    margin: '5px 0px',
    textAlign: 'center',
    color: '#0a3f6e',
    backgroundColor: '#FFFFFF',
    alignSelf: 'center',
    '@media (min-width: 1024px)': {
      alignSelf: 'flex-start',
    },
  },
  btnActions: {
    width: '100%',
    textAlign: 'center',
    marginTop: 25,
  },
  formIcon: {
    width: 30,
  },
  successScreen: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  '@media screen and (max-width: 760px)': {
  }
})

function GetInTouchForm({
  fields = {
    phone: true,
    grade: true,
    name: true,
    email: false,
  },
  afterFormSubmit = null,
  allCookies,
  placeholders = null,
  submitText = "Proceed",
  gradeSelectOption = false,
  askCouponCode = false,
}) {
  const classes = useStyles();
  const { landingStore } = useStores();
  const router = useRouter();

  const [couponCodeScreen, setCouponCodeScreen] = useState(false);

  useEffect(() => {
    const trackingUrlParams = [
      'utm_source',
      'utm_campaign',
      'utm_medium',
      'utm_term',
      'utm_content',
      'gclid',
      'referral',
    ];
    const filteredParams = trackingUrlParams.reduce<{ [key: string]: string }>((obj, key) => {
      const val = allCookies[key];
      if (val) {
        obj[key] = val;
      }
      return obj;
    }, {});
    landingStore.setPageType(router.pathname);
    landingStore.setTrackingParams(filteredParams);
  }, [allCookies,])

  // const getGrade = (grade: string) => {
  //     const gradeObj = config.GRADES.find(el => el.value === grade)
  //     return gradeObj.label;
  // }

  const setAfterFormSubmit = (actions = null) => {
    afterFormSubmit({
      studentName: landingStore.studentName,
      phone: landingStore.phone,
      grade: landingStore.grade,
      email: landingStore?.email,
    }, actions)
  }

  useEffect(() => {
    if (landingStore.submitSuccess) {
      if (askCouponCode && !landingStore.couponCode) {
        setCouponCodeScreen(true);
        landingStore.setFormFilled(false);
        setAfterFormSubmit({
          userInfoModal: true,
        })
        return;
      }
      if (afterFormSubmit) {
        setAfterFormSubmit();
      }
    }
  }, [landingStore.submitSuccess]);

  const renderGetInTouchForm = () => {
    return (
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
          landingStore.validate();
          if (landingStore.errors.size === 0) {
            landingStore.landingFormSubmit();
          }
        }}
      >
        <div>
          <BlueTextField
            variant="outlined"
            placeholder="Student Name"
            value={landingStore.studentName}
            onChange={(e) => {
              landingStore.setStudentName(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person />
                </InputAdornment>
              ),
            }}
            error={landingStore.errors.has('studentName')}
            helperText={landingStore.errors.get('studentName')}
            className={classes.field}
            required
          />
          {
            fields.grade && (
              <>
                <div style={{ width: '100%' }}>
                  {
                    gradeSelectOption
                      ?
                      <BlueTextField
                        placeholder="Select a Grade at School"
                        className={classes.field}
                        variant="outlined"
                        defaultValue="none"
                        onChange={(e) => landingStore.setGrade(e.target.value)}
                        select
                        error={landingStore.errors.has('grade')}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <GraduateCapSVG />
                            </InputAdornment>
                          ),
                        }}
                      >
                        <MenuItem
                          value="none"
                          disabled
                        >
                          Select a Grade at School
                        </MenuItem>
                        {
                          config.GRADES.map((el) => (
                            <MenuItem
                              key={el.value}
                              value={el.value}
                            >
                              {el.label}
                            </MenuItem>
                          ))
                        }
                      </BlueTextField>
                      :
                      <>
                        <div className={classes.formtext}>
                          <GraduateCapSVG />
                          <Typography
                            variant="body1"
                            className="mlr10"
                          >
                            Student's Grade at School?
                          </Typography>
                        </div>
                        <SwitchField
                          value={landingStore.grade}
                          onChange={(val) => landingStore.setGrade(val)}
                          options={config.GRADES}
                        />
                      </>
                  }
                  {landingStore.errors.has('grade') && (
                    <div className={classes.formtext} style={{ color: 'red', fontSize: '0.75rem' }}>
                      Please select an option
                    </div>
                  )}
                </div>
              </>
            )
          }
          <PhoneInput
            placeholder={placeholders && placeholders.mobile || "Mobile No"}
            inputClass={classes.phoneInput}
            containerClass={landingStore.errors.has('phone') ? classes.phoneError : classes.phoneContainer}
            country={'in'}
            preferredCountries={['in']}
            countryCodeEditable={false}
            onChange={(phone) => {
              landingStore.setPhone(phone);
            }}
            value={landingStore.phone}
            inputProps={{ required: true }}
            autoFormat={false}
          />
          {landingStore.errors.has('phone') && (
            <FormHelperText error>
              {landingStore.errors.get('phone')}
            </FormHelperText>
          )}

          {
            fields.email && (
              <BlueTextField
                variant="outlined"
                type="email"
                placeholder="Email"
                value={landingStore.email}
                onChange={(e) => {
                  landingStore.setEmail(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person />
                    </InputAdornment>
                  ),
                }}
                error={landingStore.errors.has('email')}
                helperText={landingStore.errors.get('email')}
                className={classes.field}
                required
              />
            )
          }
          {landingStore.errors.has('error') && (
            <div className={classes.formtext} style={{ color: 'red' }}>
              {landingStore.errors.get('error')}
            </div>
          )}
          <div className={classes.btnActions}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              className={classes.submitButton}
              disabled={landingStore.loading}
            >
              {landingStore.loading ? <Loading /> : submitText}
            </Button>
          </div>
        </div>
      </form>
    )
  }

  const renderCouponCodeForm = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (landingStore.couponCode) {
            landingStore.validateCouponCode()
              .then(() => {
                notify.success('Coupon code applied!');
                landingStore.setFormFilled(true);
                setAfterFormSubmit({ getRazorpayConfig: false, userInfoModal: false })
              })
              .catch(error => {
                landingStore.setFormFilled(false);
                if (error.response) {
                  notify.error(error.response.data.message);
                } else {
                  notify.error('Something went wrong. Please try again')
                }
              })
          }
        }}
      >
        <BlueTextField
          variant="outlined"
          type="text"
          placeholder="Coupon Code"
          value={landingStore.couponCode}
          onChange={(e) => {
            landingStore.setCouponCode(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Person />
              </InputAdornment>
            ),
          }}
          error={landingStore.errors.has('couponCode')}
          helperText={landingStore.errors.get('couponCode')}
          className={classes.field}
          required
        />
        <div className={classes.btnActions}>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            className={classes.submitButton}
            disabled={landingStore.loading}
          >
            {landingStore.loading ? <Loading /> : submitText}
          </Button>
        </div>
      </form>
    )
  }

  const renderForms = () => {
    if (couponCodeScreen) {
      return renderCouponCodeForm();
    }
    return renderGetInTouchForm();
  }

  return (renderForms());
}

export default withCookies(observer(GetInTouchForm));