
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import GetInTouchForm from 'components/forms/GetInTouchForm';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    header: {
        textAlign: "center",
        "& h2": {
            margin: "10px 0",
        },
        "& h4": {
            margin: "10px 0",
            fontSize: "23px",
        },
        "& p": {
            fontSize: "21px",
        }
    },
    form: {
        width: "75%",
        margin: "10px auto",
    },
    footer: {
        marginTop: "20px",
        textAlign: "center",
    },
    title: {
        flexWrap: "wrap",
    },
    "@media screen and (max-width: 760px)": {
        header: {
            width: "80%",
            margin: "0 auto",
            "& h2": {
                margin: "10px 0",
            },
            "& h4": {
                margin: "10px 0",
                fontSize: "16px",
            },
            "& p": {
                fontSize: "16px",
            }
        },
        form: {
            width: "90%"
        }
    }
})

function ApplyNowForm({
    afterFormSuccess,
    renderAfterFormSuccess,
    afterFormSubmit,
}) {
    const classes = useStyles();

    return (
        <>
            {
                afterFormSuccess
                    ?
                    renderAfterFormSuccess()
                    :
                    <>
                        <div className={classes.header}>
                            <Typography
                                variant="h2"
                            >
                                Apply Now to join StayQrious
                            </Typography>
                            <Typography
                                style={{
                                    fontWeight: 500,
                                }}
                                variant="h4"
                            >
                                Share your details & we'll reach out to you in 1-2 days.
                            </Typography>
                            <Typography
                                variant="body1"
                            >
                                Relax, we won't spam you and that's a promise.
                            </Typography>
                        </div>
                        <div className={classes.form}>
                            <GetInTouchForm
                                afterFormSubmit={afterFormSubmit}
                                submitText="Apply Now"
                            />
                        </div>

                        <div className={classes.footer}>
                            <Typography
                                variant="body1"
                            >
                                Want to get in touch with us yourself?
                            </Typography>
                            <Typography
                                variant="body1"
                                className={clsx("flex-center", classes.title)}
                            >
                                Call or WhatsApp us on&nbsp;
                                <a href="https://l.stayq.us/wa" className="text-decoration-none flex-center secondary-color">
                                    +91-8282824825
                                </a>
                                &nbsp;for more details.
                            </Typography>
                        </div>
                    </>
            }
        </>
    )
}

export default ApplyNowForm;