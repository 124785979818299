import { Backdrop, IconButton, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Close from '@material-ui/icons/Close';
import { createUseStyles } from 'react-jss';
import ApplyNowForm from './ApplyNowForm';
import Button from 'components/generics/Button';
import { useState } from 'react';
import config from 'config';
import clsx from 'clsx';
import { handleDetailFormSubmitted } from 'utils/mixpanelEvents';
import { handleLinkClick, MASTERCLASS_CLICK_EVENT } from 'utils/events';

import StarEyes from 'assets/web/StarEyes.svg';

const useStyles = createUseStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '99vh',
    margin: 'auto'
  },
  modalContainer: {
    width: 800,
    backgroundColor: '#fff',
    // border: '2px solid #000',
    padding: '30px 10px',
    borderRadius: '21px',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative'
  },
  closeSection: {
    position: 'absolute',
    right: 10,
    top: 10
  },
  successContainer: {
    textAlign: 'center',
    padding: '0 20px'
  },
  successAction: {
    // display: "flex",
    // justifyContent: "space-between",
    textAlign: 'center',
    margin: '0 auto'
  },
  experienceText: {
    fontSize: '18px!important'
  },
  enrolMasterClassText: {
    fontSize: '18px!important',
    width: '80%',
    margin: '0 auto!important'
  },
  hr: {
    borderColor: 'rgba(255, 178, 0, 0.3)',
    width: '70%'
  },
  '@media screen and (max-width: 760px)': {
    successAction: {
      width: '90%',
      flexDirection: 'column-reverse',
      '& button': {
        margin: '10px'
      },
      '& a': {
        margin: 0
      }
    },
    modalContainer: {
      height: '-webkit-fill-available'
    },
    enrolMasterClassText: {
      width: '100%'
    },
    hr: {
      width: '90%'
    }
  }
});

function ApplyNowModal({ open, setOpen }) {
  const classes = useStyles();

  const [afterFormSuccess, setAfterFormSuccess] = useState(false);
  const [leadData, setLeadData] = useState<formDataType | null>(null);

  type formDataType = {
    studentName: string;
    phone: string;
    grade: string;
    email?: string;
  };

  const afterFormSubmit = (formData: formDataType) => {
    handleDetailFormSubmitted();
    setAfterFormSuccess(true);
    setLeadData(formData);
  };

  const redirectMasterclassUrl = () => {
    const url = config.MASTERCLASS_URL;
    let params = '';

    if (leadData) {
      if (leadData.studentName) {
        params += `kidName=${leadData.studentName}&`;
      }
      if (leadData.phone) {
        params += `phone=${leadData.phone}&`;
      }
      if (leadData.grade) {
        params += `grade=${leadData.grade}`;
      }
      if (leadData.email) {
        params += `&parentEmail=${leadData.email}`;
      }
    }
    return params ? `${url}?${params}` : url + params;
  };

  const formSuccessScreen = () => {
    return (
      <div className={classes.successContainer}>
        <div className="mtb20">
          <StarEyes />
          <Typography variant="h4" className="mtb5">
            Thanks for applying!
          </Typography>
          <Typography
            variant="body1"
            className={clsx('mtb5', classes.experienceText)}
            color="textSecondary"
          >
            We will reach out to you soon.
          </Typography>
          <hr className={classes.hr} />
          <Typography
            variant="body1"
            className={clsx('mtb5', classes.enrolMasterClassText)}
          >
            Can’t wait to experience the StayQrious difference? Register for a
            live Masterclass on topics such as Coding &amp; Science
          </Typography>
        </div>
        <div className={classes.successAction}>
          {/* <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setOpen(false)}
                    >
                        Back
                    </Button> */}
          <a
            id="masterclass-apply-now-modal"
            target="_blank"
            rel="noopener noreferrer"
            href={redirectMasterclassUrl()}
            onClick={(e) =>
              handleLinkClick(
                MASTERCLASS_CLICK_EVENT,
                e,
                redirectMasterclassUrl(),
                '_blank'
              )
            }
          >
            <Button variant="contained" color="secondary">
              Register Now
            </Button>
          </a>
        </div>
        <Typography variant="body1" className="mt20">
          Free Live Sessions hosted by India’s top educators such as Aanand
          Srinivas!
        </Typography>
      </div>
    );
  };

  return (
    <>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={open}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        className={classes.modal}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <div className={classes.modalContainer}>
          <div className={classes.closeSection}>
            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </div>
          <ApplyNowForm
            afterFormSuccess={afterFormSuccess}
            renderAfterFormSuccess={formSuccessScreen}
            afterFormSubmit={afterFormSubmit}
          />
        </div>
      </Modal>
    </>
  );
}

export default ApplyNowModal;
